.wrapper {
  display: grid;
  grid-template-areas:
  "h h"
  "n c";
  grid-template-rows: 60px 1fr;
  grid-template-columns: 200px 1fr;
  grid-gap: 10px;

  width: 1200px;
  margin: 10px auto;
}
