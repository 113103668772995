header {
  grid-area: h;
  background-color: #69A7EA;
}

header img {
  margin-left: 70px;
}

header .mainTitle {
  display: inline-block;
  vertical-align: top;
  color: white;
  font-size: 25px;
  font-style: italic;
  padding: 10px 0 0 25px;
}

.loginBlock {
  display: inline-block;
  height: 60px;
  vertical-align: top;
  float: right;
  margin-right: 70px;
}

.loginBlock div {
  display: inline-block;
  margin-right: 10px;
  height: 100%;
  vertical-align: top;
}

.loginBlock button {
  color: #69a7ea;
  font-weight: bold;
  border: 2px solid white;
  border-radius: 5px;
  padding: 3px 10px;
  margin-top: 2px;
  background-color: white;
  float: right;
}

.loginBlock button:hover {
  color: white;
  background-color: #69a7ea;
}

.loginBlock a {
  line-height: 60px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  border: 2px solid white;
  border-radius: 5px;
  padding: 3px 10px;
}

.loginBlock a:hover {
  color: #69a7ea;
  border-color: #69a7ea;
  border: 2px solid white;
  background-color: white;
}

.loginBlock span {
  color: white;
  font-style: italic;
  line-height: 25px;
}

.loginBlock span:first-child {
  font-weight: bold;
  text-decoration: underline;
}
