aside {
  grid-area: n;
  display: flex;
  flex-direction: column;
}

aside a {
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: #69A7EA;
}

.navBlock .sep {
  margin-top: 20px;
  font-weight: normal;
}

aside a.active {
  text-decoration: underline;
}

.navBlock {
  background-color: #edeef0;
  padding: 22.5px 0;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
}

.navBlock > span {
  color: #69A7EA;
  font-style: italic;
  font-size: 20px;
  text-decoration: underline;
}

.navBlock .friendsList {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}

.navBlock .friendsList .friend {
  text-align: center;
  padding: 5px;
  width: 50px;
}

.navBlock .friendsList img {
  width: 100%;
}

.navBlock .friendsList span {
  font-size: 10px;
}

.navBlock:last-child {
  flex-grow: 1;
}
